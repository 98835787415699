import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Permission } from '../../models/common';
import { AuthService } from '../auth/auth.service';
import { tryParse } from '../../utils/helpers';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {}

  static get permissions(): Permission[] {
    return tryParse(window.localStorage.getItem('permissions'), []);
  }

  static set permissions(value: Permission[]) {
    window.localStorage.setItem('permissions', JSON.stringify(value));
  }

  static findPermission(uri: string): Permission {
    const item = PermissionsService.permissions;

    for (let i = 0; i < item.length; i++) {
      const itemElement = item[i];

      if (uri.includes(itemElement.slug)) {
        return itemElement;
      }
    }

    return <Permission>{};
  }

  static findPermissionBySlug(slug: string): Permission {
    const item = PermissionsService.permissions;

    for (let i = 0; i < item.length; i++) {
      const itemElement = item[i];

      if (slug === itemElement.slug) {
        return itemElement;
      }
    }

    return {} as Permission;
  }

  getAll() {
    const permissions = PermissionsService.permissions;

    if (permissions.length) {
      return of(permissions);
    }

    return this.http
      .get<Permission[]>(`${environment.apiUrl}/permissions`, {
        params: new HttpParams({
          fromObject: {
            profile_id: this.auth.profileId!.toString(),
          },
        }),
      })
      .pipe(
        tap((value: Permission[]) => (PermissionsService.permissions = value)),
        catchError(() => {
          return of([]);
        })
      );
  }
}
